.first-main-container {
  height :  828px;
  background: linear-gradient(180deg, rgba(246, 246, 246, 0) 0%, #F6F6F6 99.99%);
}

.second-main-container {
  background-color: #FFFFFF;
}

.first-main-container .title-container {
  top : 311px;
}

#korean-logo {
  visibility : hidden;
}

#korean-logo-primary {
  width : 64px;
  height : 29px;
  margin-left: 8px;
  margin-right: 8px;
}

.alt-yellow {
  width : 17.28px;
  height : 24px;
  top : 199px;
  left : 81px;
}

.alt-purple-1 {
  width : 16px;
  height : 23px;
  top : 263px;
  right : 60px;
}

.alt-blue {
  width : 17.3px;
  height : 25px;
  right : 4.69px;
  top : 357px;
}

.alt-pink {
  width  : 18.62px;
  height : 27px;
  left : 12px;
  top : 576px;
}

.alt-purple-2 {
  width : 18.07px;
  height : 25.89px;
  bottom : 127.11px;
  right : 50.93px;
}

#main-pink {
  width : 29px;
  top : 369px;
  right : 155px;
}

#main-blue-1 {
  width : 34.68px;
  left : 26px;
  bottom : 327.66px;
}

#main-yellow {
  width : 36px;
  right : 22px;
  bottom : 235px;
}

#main-blue-2 {
  width : 49px;
  left : 142px;
  bottom : 53px;
}

#main-pink-dot {
  top : 415px;
  right : 169.5px;
}

#main-blue-1-dot {
  left : 43px;
  bottom : 340px;
}

#main-yellow-dot {
  right : 40px;
  bottom : 250px;
}

#main-blue-2-dot {
  left : 167px;
  bottom : 70px;
}

.yodaPick-screenshot {
  filter: drop-shadow(0px 20px 28px rgba(0, 0, 0, 0.11));
}

::-webkit-scrollbar {
  display: none;
}

/* md tailwind */
@media (min-width: 768px) {
  .first-main-container {
    height : 1249px;
  }

  #korean-logo {
    visibility:visible;
    width : 46.43px;
    height : 20.71px;
    margin-left : 7px
  }

  .first-main-container .title-container {
    top : 432px;
  }

  #korean-logo-primary {
    width : 107px;
    height : 48.48px;
      margin-left: 15px;
  margin-right: 15px;
  }

  .alt-yellow {
    width : 27.68px;
    height : 38.44px;
    top : 287px;
    left : 178px;
  }
  

  .alt-purple-1 {
    width : 25.63px;
    height : 36.84px;
    top : 387px;
    right : 159.37px;
  }

  .alt-blue {
    width : 27.72px;
    height : 40.04px;
    right : 87.28px;
    top : 543px;
  }


  .alt-pink {
    width  : 29.82px;
    height : 43.25px;
    left : 51px;
    top : 848px;
  }

  .alt-purple-2 {
    width : 28.95px;
    height : 41.47px;
    bottom : 185.53px;
    right : 131px;
  }

  #main-pink {
    width : 46.45px;
    top : 549px;
    right : 328.46px;
  }

  #main-blue-1 {
    width : 55.55px;
    left : 92px;
    bottom : 506px;
  }
  #main-yellow {
    width : 57.66px;
    right : 73px;
    bottom : 347.31px;
  }

  #main-blue-2 {
    width : 78.5px;
    left : 314.64px;
    bottom : 87.42px;
  }

  #main-pink-dot {
    top : 620px;
    right : 352px;
  }

  #main-blue-1-dot {
    left : 120px;
    bottom : 530px;
  }
  #main-yellow-dot {
    right : 97px;
    bottom : 370px;
  }

  #main-blue-2-dot {
    left : 353px;
    bottom : 120px;
  }
}


/* lg tailwind */
@media (min-width: 1024px) { 
  .first-main-container {
    height : 1375px
  }



  #korean-logo {
    visibility:visible;
    width : 65px;
    height : 29px;
    margin-left : 10px
  }

  .first-main-container .title-container {
    top : 442px;
  }

  #korean-logo-primary {
    width : 150.32px;
    height : 69px;
    margin-left: 24px;
    margin-right: 24px;
  }

  .alt-yellow {
    width : 36.11px;
    height : 50px;
    top : 276px;
    left : 256px;
  }


  .alt-purple-1 {
    width : 33.88px;
    height : 48px;
    top : 346px;
    right : 290px;
  }

  .alt-blue {
    width : 43px;
    height : 60px;
    right : 148px;
    top : 526px;
  }

  .alt-pink {
    width  : 35px;
    height : 50px;
    left : 124px;
    top : 908px;
  }

  .alt-purple-2 {
    width : 34.91px;
    height : 50px;
    bottom : 89px;
    right : 227.09px;
  }

  #main-pink {
    width : 58px;
    top : 549px;
    right : 664px;
  }

  #main-blue-1 {
    width : 71px;
    left : 240px;
    bottom : 553px;
  }

  #main-yellow {
    width : 71px;
    right : 262px;
    bottom : 366px;
  }

  #main-blue-2 {
    width : 83px;
    left : 551px;
    bottom : 39px;
  }

  #main-pink-dot {
    top : 635px;
    right : 693px;
  }

  #main-blue-1-dot {
    left : 275px;
    bottom : 580px;
  }

  #main-yellow-dot {
    right : 297px;
    bottom : 400px;
  }

  #main-blue-2-dot {
    left : 595px;
    bottom : 75px;
  }
}