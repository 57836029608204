.fade-in {
  visibility: visible;
  opacity: 1;
  z-index: 9;
  transition: visibility 1s 1s, opacity 1s 1s linear, z-index 1s 1s linear;
}

.fade-out {
  visibility: hidden;
  opacity: 0;
  z-index: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear, z-index 0.5s linear;
}

.z-fade-in {
  z-index: 9;
  transition: z-index 1s linear;
}

.z-fade-out {
  z-index: 0;
  transition: z-index 0.5s linear;
}

.button-default {
  transform: translateY(140%);
}

.text-fade-in {
  transform-origin: bottom;
  transform: translateY(0);
  opacity: 0.8;
  transition: transform 0.5s 2s linear, opacity 1s 2s linear;
}

.button-fade-in {
  transform-origin: bottom;
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.5s 3s linear, opacity 1s 3s linear;
}

.step-6-button-fade-in {
  transform-origin: bottom;
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.5s 0.5s linear, opacity 1s 0.5s linear;
}

.button-fade-out {
  transform-origin: bottom;
  transform: translateY(140%);
  opacity: 0;
  transition: transform 0.5s linear, opacity 1s linear;
}

.step-3-intro-fade-in {
  visibility: visible;
  opacity: 1;
  z-index: 9;
  background-position: right bottom;
  transition: visibility 1s 1s, opacity 1s 1s linear, z-index 1s 1s linear, background-position 2s 2s linear;
}

.step-3-intro-fade-out {
  background-position: right bottom;
  visibility: hidden;
  opacity: 0;
  z-index: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear, z-index 0.5s linear;
}

.step-3-fade-in {
  visibility: visible;
  opacity: 1;
  z-index: 9;
  transition: visibility 1s, opacity 1s linear, z-index 1s linear;
}

.step-3-fade-out {
  visibility: hidden;
  opacity: 0;
  z-index: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear, z-index 0.5s linear;
}

.step-3-text-fade-in {
  transform-origin: bottom;
  transform: translateY(0);
  opacity: 0.8;
  transition: transform 0.5s 1s linear, opacity 1s 1s linear;
}

.step-3-button-fade-in {
  transform-origin: bottom;
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.5s 2s linear, opacity 1s 2s linear;
}

@keyframes sizingAnimation {
  0%   { width: 100%; height: 100%; font-size: 1.125rem; }
  50%  { width: 90%; height: 90%; font-size: 1.0125rem; }
  100% { width: 100%; height: 100%; font-size: 1.125rem; }
}

.sizing-animate {
  animation: sizingAnimation 2s infinite;
}

@keyframes flickerAnimation {
  0%   { opacity: 1; }
  50%  { opacity: 0; }
  100% { opacity: 1; }
}

.animate-flicker {
  animation: flickerAnimation 3s infinite;
}

@keyframes blinkingTopAnimation {
  0% { transform: translateY(); }
  30% { transform: translateY(-70%); }
  60% { transform: translateY(-50%); }
  100% { transform: translateY(calc(-100% - 60px)); }
}

@keyframes blinkingBottomAnimation {
  0% { transform: translateY(); }
  30% { transform: translateY(70%); }
  60% { transform: translateY(50%); }
  100% { transform: translateY(calc(100% + 60px)); }
}

.animate-top-blinking {
  animation: blinkingTopAnimation 2s forwards;
}

.animate-bottom-blinking {
  animation: blinkingBottomAnimation 2s forwards;
}

.large-shadow {
  box-shadow: 0 0 30px 30px #000;
}

@keyframes zoomIn {
  0% {
    width: 16rem;
  }
  100% {
    width: 100%;
  }
}

.movie-fade-in {
  visibility: visible;
  opacity: 1;
  z-index: 9;
  transition: visibility 0.5s, opacity 0.5s linear, z-index 0.5s linear;
}

.movie-zoom-in {
  animation: 0.5s 0.5s zoomIn forwards;
}

.movie-fade-out {
  width: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear, z-index 0.5s linear; 
}

.step-8-conv-1-fade-in {
  transform-origin: bottom;
  transform: translateY(0);
  opacity: 0.8;
  transition: transform 0.5s 2s linear, opacity 1s 2s linear;
}

@keyframes step-8-conv-2 {
  0% {
    transform-origin: bottom;
    transform: translateY(100%);
    opacity: 0;
  }

  50% {
    transform-origin: bottom;
    transform: translateY(100%);
    opacity: 0;
  }

  75% {
    transform-origin: bottom;
    transform: translateY(0);
    opacity: 0.8;
  }

  100% {
    transform-origin: bottom;
    transform: translateY(100%);
    opacity: 0;
  }
}

.step-8-conv-2 {
  animation: 6s step-8-conv-2 forwards;
}

.step-8-conv-3-fade-in {
  transform-origin: bottom;
  transform: translateY(0);
  opacity: 0.8;
  transition: transform 0.5s 3.5s linear, opacity 1s 3.5s linear;
}

@keyframes step-8-conv-4 {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  40% {
    transform: translateY(0);
    opacity: 0.8;
  }

  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

.step-8-conv-4 {
  transform-origin: bottom;
  animation: 3s step-8-conv-4 forwards;
}

.step-8-conv-5-fade-in {
  transform-origin: bottom;
  transform: translateY(0);
  opacity: 0.8;
  transition: transform 1s 2s linear, opacity 2s 2s linear;
}

.step-8-btn-fade-in {
  transform-origin: bottom;
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.5s 6s linear, opacity 1s 6s linear; 
}

.step-8-1-btn-fade-in {
  transform-origin: bottom;
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.5s 3s linear, opacity 1s 3s linear; 
}

.step-9-btn-fade-in {
  transform-origin: bottom;
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.5s 1s linear, opacity 1s 1s linear; 
}

.description-color {
  color: #222222;
}